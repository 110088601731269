import { useEffect, useState } from "react";

import "./EditLevel.scss";
import http from "../../../axios.config";
import useToken from "../../../Hooks/useToken";

function EditLevel({
  editModal,
  setEditModal,
  api,
  id,
  default_title,
  default_mistake,
}) {
  const [isModalOpen, setIsModalOpen] = useState(editModal);
  const [token, setToken] = useToken();
  console.log(id);
  const handleSubmit = (e) => {
    e.preventDefault();
    const { title, mistake } = e.target.elements;
    const data = {
      id: id,
      title: title.value,
      mistake_count: mistake.value,
    };
    console.log(data);
    http
      .put(api, data, {
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          window.location.reload();
        } else if (response.data.status === 401) {
          setToken();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeModal = () => {
    setEditModal(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {editModal ? (
        <div className="modal_bg" onClick={closeModal}>
          <div className="add_level_wrapper" onClick={stopPropagation}>
            <button
              className="close"
              onClick={() => {
                setEditModal(false);
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            <h1>Edit</h1>
            <form onSubmit={handleSubmit}>
              <h2>Level name</h2>
              <input
                required
                type="text"
                autoFocus
                placeholder="Beginner"
                defaultValue={default_title}
                name="title"
              />
              <h2>Mistake count</h2>
              <input
                required
                type="text"
                placeholder="2"
                name="mistake"
                defaultValue={default_mistake}
              />
              <button className="add_btn" type="submit">
                Edit
              </button>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default EditLevel;
