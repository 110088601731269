import React from "react";
import { useEffect, useState } from "react";

import "./AddTest.scss";
import Close from "../../../assets/images/icons/close.svg";
import http from "../../../axios.config";
import useToken from "../../../Hooks/useToken";

function AddTest({ addModal, setAddModal, api }) {
  const [isModalOpen, setIsModalOpen] = useState(addModal);
  const [token, setToken] = useToken();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const { title, option_1, option_2, option_3, answer, level_id } =
      e.target.elements;

    const data = {
      title: title.value,
      option_1: option_1.value,
      option_2: option_2.value,
      option_3: option_3.value,
      option_answer: answer.value,
      level_id: level_id.value,
    };

    http
      .post(api, data, {
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          window.location.reload();
        } else if (response.data.status === 401) {
          setToken();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeModal = () => {
    setAddModal(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
  };
  return (
    <>
      {addModal ? (
        <div className="modal_bg" onClick={closeModal}>
          <div className="add_test_wrapper" onClick={stopPropagation}>
            <button
              className="close"
              onClick={() => {
                setAddModal(false);
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            <h1>Add question</h1>
            <form onSubmit={handleSubmit}>
              <h2>Title</h2>
              <input
                required
                type="text"
                autoFocus
                placeholder="If you ________ me, what would you do"
                name="title"
              />
              <div className="d-flex">
                <div>
                  <h2>Option A</h2>
                  <input
                    required
                    type="text"
                    placeholder="were"
                    name="option_1"
                  />
                </div>
                <div>
                  <h2>Option B</h2>
                  <input
                    required
                    type="text"
                    placeholder="was"
                    name="option_2"
                  />
                </div>
                <div>
                  <h2>Option C</h2>
                  <input
                    required
                    type="text"
                    placeholder="have been"
                    name="option_3"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <h2>Answer</h2>
                  <select
                    className="answer"
                    value={selectedAnswer}
                    onChange={handleAnswerChange}
                    name="answer"
                  >
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                  </select>
                </div>
                <div>
                  <h2>Level</h2>
                  <select
                    className="language"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    name="level_id"
                  >
                    <option value="1">Biginner</option>
                    <option value="2">Elementary</option>
                    <option value="3">Pre intermediate</option>
                    <option value="4">Intermediate</option>
                  </select>
                </div>
              </div>

              <button className="add_btn" type="submit">
                Add
              </button>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default AddTest;
