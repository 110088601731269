import { useState, useEffect } from "react";
import Header from "../../Containers/Header/Header";
import http from "../../axios.config";
import Edit from "../../assets/images/icons/edit.svg";
import DeleteSvg from "../../assets/images/icons/delete.svg";
import Pagination from "../../Components/Pagination/Pagination";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import AddTest from "../../Components/Add/AddTest/AddTest";
import EditTest from "../../Components/Edit/EditTest/EditTest";

function Tests() {
  const [offset, setOffset] = useState(0);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [selectedTestTitle, setSelectedTestTitle] = useState(null);
  const [selectedTestLevelid, setSelectedTestLevelid] = useState(null);
  const [selectedTestAnswer, setSelectedTestAnswer] = useState(null);
  const [selectedTestOption1, setSelectedTestOption1] = useState(null);
  const [selectedTestOption2, setSelectedTestOption2] = useState(null);
  const [selectedTestIdOption3, setSelectedTestOption3] = useState(null);

  const [testsList, setTestsList] = useState({
    isFetched: false,
    data: [],
    err: false,
  });

  useEffect(() => {
    http
      .get(`tests/list?limit=10&offset=${offset}`, {})
      .then((res) =>
        setTestsList({
          isFetched: true,
          data: res.data.data,
          err: false,
        })
      )
      .catch((error) =>
        setTestsList({
          isFetched: true,
          data: [],
          err: error,
        })
      );
  }, [offset]);

  function truncateTextAfterWords(text) {
    const words = text.split(" ");
    if (words.length > 4) {
      return words.slice(0, 4).join(" ") + "...";
    }
    return text;
  }
  return (
    <div>
      {testsList.isFetched ? (
        <div className="container">
          <Header />
          <div className="table_wrap ">
            <AddTest
              addModal={addModal}
              setAddModal={setAddModal}
              api={"test/add"}
            />
            <DeleteModal
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              api={"test/delete"}
              id={"id"}
              mark_id={selectedTestId - 0}
            />
            <EditTest
              editModal={editModal}
              setEditModal={setEditModal}
              api={"test/edit"}
              id={selectedTestId}
              default_title={selectedTestTitle}
              default_answer={selectedTestAnswer}
              default_levelid={selectedTestLevelid}
              default_option1={selectedTestOption1}
              default_option2={selectedTestOption2}
              default_option3={selectedTestIdOption3}
            />
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Options</th>
                  <th>Answer</th>
                  <th>Level</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {testsList.data?.map((test, index) => (
                  <tr key={index} className="table_tr">
                    <td>{++index}</td>
                    <td>{truncateTextAfterWords(test.test_title)}</td>
                    <td>
                      A.{test.test_option_1}
                      <br />
                      B.{test.test_option_2}
                      <br />
                      C.{test.test_option_3}
                    </td>
                    <td>{test.test_option_answer}</td>
                    <td>{test.level_title}</td>
                    <td className="btns">
                      <button
                        className="edit"
                        onClick={() => {
                          setEditModal(true);
                          setSelectedTestId(test.test_id);
                          setSelectedTestTitle(test.test_title);
                          setSelectedTestAnswer(test.test_option_answer);
                          setSelectedTestLevelid(test.level_id);
                          setSelectedTestOption1(test.test_option_1);
                          setSelectedTestOption2(test.test_option_2);
                          setSelectedTestOption3(test.test_option_3);
                        }}
                      >
                        <img src={Edit} alt="edit" />
                      </button>
                      <button
                        className="delete"
                        onClick={() => {
                          setDeleteModal(true);
                          setSelectedTestId(test.test_id);
                        }}
                      >
                        <img src={DeleteSvg} alt="deleteBtn" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="add">
            <button
              onClick={() => {
                setAddModal(true);
              }}
            >
              Add
            </button>
          </div>
          <Pagination
            offset={offset}
            setOffset={setOffset}
            data={testsList.data}
          />
        </div>
      ) : (
        <div className="loader">
          <div className="loader__wrap">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
            <div className="bar5"></div>
            <div className="bar6"></div>
            <div className="bar7"></div>
            <div className="bar8"></div>
            <div className="bar9"></div>
            <div className="bar10"></div>
            <div className="bar11"></div>
            <div className="bar12"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Tests;
