import { useState, useEffect } from "react";

import Header from "../../Containers/Header/Header";
import http from "../../axios.config";
import "./Seminar.scss";
import useToken from "../../Hooks/useToken";
import Pagination from "../../Components/Pagination/Pagination";

function Seminar() {
  const [token, setToken] = useToken();
  const [offset, setOffset] = useState(0);

  const [studentsList, setStudentsList] = useState({
    isFetched: false,
    data: [],
    err: false,
  });

  useEffect(() => {
    http
      .get(`participants/list?limit=20&offset=${offset}`, {
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status === 401) {
          setToken();
        } else {
          setStudentsList({
            isFetched: true,
            data: res.data.data,
            err: false,
          });
        }
      })
      .catch((error) =>
        setStudentsList({
          isFetched: true,
          data: [],
          err: error,
        })
      );
  }, [offset]);

  const checkboxChange = (e) => {
    const id = JSON.parse(e.target.dataset.id);
    const status = e.target.checked;

    fetch(
      "https://api.ept.myschoollc.uz/api/v1/participants/edit/called",
      {
        method: "PUT",
        body: JSON.stringify({
          id: id,
          status: status,
        }),
        headers: { token: token, "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const handleExport = async () => {
    try {
      const response = await fetch(
        "https://api.ept.myschoollc.uz/api/v1/participants/download"
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "seminar.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <div>
      {studentsList.isFetched ? (
        <div className="container">
          <Header />
          <div className="table_wrap ">
            <button
              className="btn"
              style={{ marginBottom: "20px" }}
              onClick={handleExport}
            >
              Download Excel
            </button>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Education center</th>
                  <th>Region</th>
                  <th>Called</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {studentsList.data?.map((student, index) => (
                  <tr key={index} className="table_tr">
                    <td>{++index}</td>
                    <td>{student.participant_name}</td>
                    <td>{student.participant_phone_number}</td>
                    <td>{student.participant_education_center}</td>
                    <td>{student.participant_region}</td>
                    <td>
                      <input
                        type="checkbox"
                        className="called"
                        data-id={student.participant_id}
                        defaultChecked={student.participant_called}
                        onChange={checkboxChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            offset={offset}
            setOffset={setOffset}
            data={studentsList.data}
          />
        </div>
      ) : (
        <div className="loader">
          <div className="loader__wrap">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
            <div className="bar5"></div>
            <div className="bar6"></div>
            <div className="bar7"></div>
            <div className="bar8"></div>
            <div className="bar9"></div>
            <div className="bar10"></div>
            <div className="bar11"></div>
            <div className="bar12"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Seminar;
