import { useState, useEffect } from "react";

import Header from "../../Containers/Header/Header";
import http from "../../axios.config";
import Edit from "../../assets/images/icons/edit.svg";
import DeleteSvg from "../../assets/images/icons/delete.svg";
import AddLevel from "../../Components/Add/AddLevel/AddLevel";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";
import EditLevel from "../../Components/Edit/EditLevel/EditLevel";

function Levels() {
  const [levels, setlevels] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedLevelId, setSelectedLevelId] = useState(null);
  const [selectedLevelTitle, setSelectedLevelTitle] = useState("");
  const [selectedLevelMistake, setSelectedLevelMistake] = useState(null);
  const [levelsList, setLevelsList] = useState({
    isFetched: false,
    data: [],
    err: false,
  });

  useEffect(() => {
    http
      .get(`levels/list`, {})
      .then((res) =>
        setLevelsList({
          isFetched: true,
          data: res.data.data,
          err: false,
        })
      )
      .catch((error) =>
        setLevelsList({
          isFetched: true,
          data: [],
          err: error,
        })
      );
  }, []);
  return (
    <div>
      {levelsList.isFetched ? (
        <div className="container">
          <Header />
          <div className="table_wrap ">
            <AddLevel
              addModal={addModal}
              setAddModal={setAddModal}
              api={"level/add"}
            />
            <DeleteModal
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              api={"level/delete"}
              id={"id"}
              mark_id={selectedLevelId - 0}
            />
            <EditLevel
              editModal={editModal}
              setEditModal={setEditModal}
              api={"level/edit"}
              id={selectedLevelId}
              default_title={selectedLevelTitle}
              default_mistake={selectedLevelMistake}
            />
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Mistake</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {levelsList.data?.map((level, index) => (
                  <tr key={index} className="table_tr">
                    <td>{level.level_id}</td>
                    <td>{level.level_title}</td>
                    <td>{level.level_mistake_count} mistakes</td>
                    <td></td>
                    <td></td>
                    <td className="btns">
                      <button
                        className="edit"
                        onClick={() => {
                          setEditModal(true);
                          setSelectedLevelId(level.level_id);
                          setSelectedLevelTitle(level.level_title);
                          setSelectedLevelMistake(level.level_mistake_count);
                        }}
                      >
                        <img src={Edit} alt="edit" />
                      </button>
                      <button
                        className="delete"
                        onClick={() => {
                          setDeleteModal(true);
                          setSelectedLevelId(level.level_id);
                        }}
                      >
                        <img src={DeleteSvg} alt="deleteBtn" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="add">
            <button
              onClick={() => {
                setAddModal(true);
              }}
            >
              Add
            </button>
          </div>
        </div>
      ) : (
        <div className="loader">
          <div className="loader__wrap">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
            <div className="bar5"></div>
            <div className="bar6"></div>
            <div className="bar7"></div>
            <div className="bar8"></div>
            <div className="bar9"></div>
            <div className="bar10"></div>
            <div className="bar11"></div>
            <div className="bar12"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Levels;
