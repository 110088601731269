import { useState, useEffect } from "react";

import Header from "../../Containers/Header/Header";
import http from "../../axios.config";
import "./Students.scss";
import DeleteSvg from "../../assets/images/icons/delete.svg";
import useToken from "../../Hooks/useToken";
import Pagination from "../../Components/Pagination/Pagination";
import DeleteModal from "../../Components/DeleteModal/DeleteModal";

function Students() {
  const [token, setToken] = useToken();
  const [offset, setOffset] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [studentsList, setStudentsList] = useState({
    isFetched: false,
    data: [],
    err: false,
  });

  useEffect(() => {
    http
      .get(`students/list?limit=10&offset=${offset}`, {
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status === 401) {
          setToken();
        } else {
          setStudentsList({
            isFetched: true,
            data: res.data.data,
            err: false,
          });
        }
      })
      .catch((error) =>
        setStudentsList({
          isFetched: true,
          data: [],
          err: error,
        })
      );
  }, [offset]);

  const checkboxChange = (e) => {
    const id = JSON.parse(e.target.dataset.id);
    const status = e.target.checked;

    fetch("https://api.ept.myschoollc.uz/api/v1/student/edit/called", {
      method: "PUT",
      body: JSON.stringify({
        id: id,
        status: status,
      }),
      headers: { token: token, "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {studentsList.isFetched ? (
        <div className="container">
          <Header />
          <DeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            api={"student/delete"}
            id={"id"}
            mark_id={selectedId - 0}
          />
          <div className="table_wrap ">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Level</th>
                  <th>Corrects</th>
                  <th>Mistakes</th>
                  <th>Ielts date</th>
                  <th>Called</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {studentsList.data?.map((student, index) => (
                  <tr key={index} className="table_tr">
                    <td>{++index}</td>
                    <td>{student.student_name}</td>
                    <td>{student.student_phone}</td>
                    <td>{student.level_title}</td>
                    <td>{student.correct_answers}</td>
                    <td>{student.mistake_answers}</td>
                    <td>
                      {student.ielts_date == null ? "-" : student.ielts_date}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="called"
                        data-id={student.student_id}
                        defaultChecked={student.called}
                        onChange={checkboxChange}
                      />
                    </td>
                    <td className="btns">
                      <button
                        className="delete"
                        onClick={() => {
                          setDeleteModal(true);
                          setSelectedId(student.student_id);
                        }}
                      >
                        <img src={DeleteSvg} alt="deleteBtn" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            offset={offset}
            setOffset={setOffset}
            data={studentsList.data}
          />
        </div>
      ) : (
        <div className="loader">
          <div className="loader__wrap">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <div className="bar4"></div>
            <div className="bar5"></div>
            <div className="bar6"></div>
            <div className="bar7"></div>
            <div className="bar8"></div>
            <div className="bar9"></div>
            <div className="bar10"></div>
            <div className="bar11"></div>
            <div className="bar12"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Students;
