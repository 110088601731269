import { Link, NavLink } from "react-router-dom";
import { useState } from "react";

import "./Sidebar.scss";
import Logo from "../../assets/images/logo.png";

function Sidebar() {
  //dropdown useStates
  const [carOpen, setCarOpen] = useState(false);
  const [motorcycleOpen, setMotorcycleOpen] = useState(false);
  const [mhOpen, setMhOpen] = useState(false);
  const [vanOpen, setVanOpen] = useState(false);
  const [truckOpen, setTruckOpen] = useState(false);
  const [trailerOpen, setTrailerOpen] = useState(false);
  const [sttOpen, setSttOpen] = useState(false);
  const [stOpen, setStOpen] = useState(false);
  const [coacheOpen, setCoacheOpen] = useState(false);
  const [avOpen, setAvOpen] = useState(false);
  const [cmOpen, setCmOpen] = useState(false);
  const [ftOpen, setFtOpen] = useState(false);
  const [usersOpen, setUsersOpen] = useState(false);

  return (
    <div className="nav-bar_wrapper">
      <div className="nav-bar_wrapper_inner">
        <div className="logo_wrapper">
          <Link className="nav_link" to="/admins">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <nav>
          <ul className="nav_list">
            <li className="nav_list_item">
              <NavLink className="nav_link" to="/admins">
                Admins
              </NavLink>
            </li>
            <li className="nav_list_item">
              <NavLink
                className="nav_link"
                to="/students"
                onClick={() => setUsersOpen(!usersOpen)}
              >
                Students
              </NavLink>
            </li>
            <li className="nav_list_item">
              <NavLink
                className="nav_link"
                to="/customers"
                onClick={() => setUsersOpen(!usersOpen)}
              >
                Customers
              </NavLink>
            </li>
            <li className="nav_list_item">
              <NavLink className="nav_link" to="/levels">
                Levels
              </NavLink>
            </li>
            <li className="nav_list_item">
              <NavLink className="nav_link" to="/tests">
                Tests
              </NavLink>
            </li>
            <li className="nav_list_item">
              <NavLink className="nav_link" to="/seminar">
                Seminar
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
