import React from "react";
import Header from "../../Containers/Header/Header";
import Sidebar from "../../Containers/SIdebar/Siderbar";
import { Routes, Route } from "react-router-dom";
import Admins from "../Admins/Admins";
import Levels from "../Levels/Levels";
import Tests from "../Tests/Tests";
import Students from "../Students/Students";
import Customers from "../Customers/Customers";
import Seminar from "../Seminar/Seminar";

function Admin() {
  return (
    <div className="app">
      <div>
        <Sidebar />
      </div>
      <div>
        <Routes>
          <Route path="/admins" element={<Admins />} />
          <Route path="/students" element={<Students />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/levels" element={<Levels />} />
          <Route path="/tests" element={<Tests />} />
          <Route path="/seminar" element={<Seminar />} />
        </Routes>
      </div>
    </div>
  );
}

export default Admin;
